/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isEmailAddress, isValidPassword, isValidUsername } from '../../../../../shared/Form/Validators';
import {
  getActiveIntegrations,
  integrationInstanceName,
  isEmpty,
  isFormNull,
  isNotEmpty,
} from '../../../../../shared/Utilities';
import { CurrentUserContext } from '../../../../../Contexts/CurrentUser';
// import { FlashMessageQueueContext } from '../../../../../Contexts/FlashMessageQueue';
import Form from '../../../../../shared/Form';
// import Notification from '../../../../../shared/Notification';
import { makeRequest } from '../../../../../../legacy/io';
import NotificationSubscriptions from '../../../../../shared/NotificationSubscriptions';
import { isAdmin } from '../../../../App/AccessControl';
import { getFieldValues } from '../../../../../shared/Form/Shared';
import { FlashMessageQueueContext } from '../../../../../Contexts/FlashMessageQueue';
import InlineSVG from '../../../../../shared/InlineSVG';

const DetailsAndPreferences = ( { user } ) => {

  const isJiraIntegration = ( fieldValue ) => {
    if ( isFormNull( fieldValue ) || isEmpty( fieldValue ) ) {
      return false;
    }
    if ( isNotEmpty( activeIntegrations ) && isNotEmpty( activeIntegrations.jira ) && isNotEmpty( fieldValue ) ) {
      const jiraIDs = activeIntegrations.jira.map( i => i.id );

      return jiraIDs.includes( fieldValue );
    }
  };

  const FIELDS = {
    left: {
      fields: [
        {
          type: 'hidden',
          attribute: 'authentication_provider_id',
          defaultValue: '00000000-0000-0000-0000-000000000000',
        },
        {
          type: 'text',
          label: 'Username',
          attribute: 'username',
          required: true,
          validators: [ isValidUsername ],
          placeholder: 'jsmith',
          defaultValue: '',
        },
        {
          warnIf: [
            { attribute: 'remediation_export_setting', check: isFormNull },
            { attribute: 'email_address', check: isFormNull },
          ],
          // eslint-disable-next-line max-len
          warningMessage: 'Remediation tickets will not be able to be sent to this user without entering a valid email address',
          type: 'text',
          label: 'Email Address',
          attribute: 'email_address',
          validators: [ isEmailAddress ],
          placeholder: 'jsmith@example.com',
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'authentication_provider_id', value: '00000000-0000-0000-0000-000000000000' },
          type: 'password',
          label: 'Password',
          attribute: 'password',
          required: true,
          validators: [ isValidPassword ],
          placeholder: 'Not shown, only edit when changing password',
          defaultValue: '',
        },

      ],
    },
    right: {
      fields: [
        {
          type: 'text',
          label: 'Given Name',
          attribute: 'given_name',
          placeholder: 'Jane',
          defaultValue: '',
        },
        {
          type: 'text',
          label: 'Family Name',
          attribute: 'family_name',
          placeholder: 'Smith',
          defaultValue: '',
        },
        {
          type: 'select',
          label: 'Preferred Ticketing Integration',
          attribute: 'remediation_export_setting',
          defaultValue: '',
          asDropdown: true,
          options: [],
        },
        {
          includeIf: { attribute: 'remediation_export_setting', check: isJiraIntegration },
          type: 'searchResults',
          label: 'Mapped User',
          attribute: 'mapped_user_id',
          defaultValue: '',
          noRisk: true,
          recordType: 'external_user',
        },
      ],
    },
  };

  const [ availableNotifications, setAvailableNotifications ] = React.useState( null );
  const [ fields, setFields ] = React.useState( [] );
  const [ updatedForm, setUpdatedForm ] = React.useState( {} );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  // const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ currentUser ] = React.useContext( CurrentUserContext );
  const [ activeIntegrations, setActiveIntegrations ] = React.useState( {} );

  const setupForm = async () => {
    // initialize with one optGroup "no integration"
    const integrationOptions = [
      {
        label: 'No Integration',
        type: 'none',
        options: { '': <span>Email me directly</span> },
      },
    ];

    if ( isNotEmpty( activeIntegrations ) ) {

      if ( isNotEmpty( activeIntegrations.email ) ) {
        const emailGroup = { label: 'Email', type: 'email', options: {} };
        activeIntegrations.email.map( i => {
          emailGroup.options[i.id] = <span>
            <InlineSVG type="mail" version="primary" />
            { integrationInstanceName( i, true, false ) }
          </span>;
        } );
        integrationOptions.push( emailGroup );
      }

      if ( isNotEmpty( activeIntegrations.jira ) ) {
        const jiraGroup = { label: 'Atlassian Jira', type: 'jira', options: {} };
        activeIntegrations.jira.map( i => {
          jiraGroup.options[i.id] = <span>
            <InlineSVG type="jiraLogo" version="special" />
            { integrationInstanceName( i, true, false ) }
          </span>;
        } );
        integrationOptions.push( jiraGroup );
      }
    }

    const _fields = { ...FIELDS };
    const remediationField = _fields.right.fields.find( f => f.attribute === 'remediation_export_setting' );
    remediationField.options = integrationOptions;
    setFields( _fields );

    const availablenotificationsRequest = await makeRequest( 'TYPES', '/notification_settings', {} );

    if ( isNotEmpty( availablenotificationsRequest ) && isNotEmpty( availablenotificationsRequest.results ) ) {
      setAvailableNotifications( availablenotificationsRequest.results );
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( user ) ) {
      getActiveIntegrations().then( _activeIntegrations => {
        setActiveIntegrations( _activeIntegrations );
      } );
    }
  }, [ user ] );

  React.useEffect( () => {
    setupForm();
  }, [ activeIntegrations ] );

  const onSaveCallback = async () => {
    if (
      isNotEmpty( updatedForm )
      && isNotEmpty( updatedForm.fieldStates )

    ) {
      const userParams = getFieldValues( updatedForm.fieldStates, 'user' );
      userParams.id = user.id;

      if ( isEmpty( userParams.mapped_user_id ) ) {
        delete userParams.mapped_user_id;
      }

      if ( isFormNull( userParams.remediation_export_setting ) ) {
        delete userParams.remediation_export_setting;
      }

      const profileVersion = isNotEmpty( currentUser ) && currentUser.id === userParams.id;

      delete userParams.authentication_provider_id;

      if ( profileVersion ) {
        delete userParams.id;
        await makeRequest( 'PUT', 'fe/profile/UPDATE', userParams );
      } else {
        await makeRequest( 'PUT', '/fe/user/UPDATE', [ userParams ] );
      }

      addFlashMessage( {
        body: 'Saved details and preferences',
        type: 'success',
      } );
    }
  };

  return (
    <React.Fragment>
      <h2 className="userDetails__MainPanel__Header">Access and Credentials</h2>
      {
        ( isAdmin( currentUser ) && isNotEmpty( user ) && isNotEmpty( fields ) ) &&
        <Form
          recordType="user"
          fields={fields}
          existingRecord={user}
          onChangeCallback={setUpdatedForm}
        />
      }
      {
        ( isAdmin( currentUser ) && isNotEmpty( availableNotifications ) ) &&
        <NotificationSubscriptions
          availableNotifications={availableNotifications}
          user={user}
          onSaveCallback={onSaveCallback}
          elementClass="userDetails__MainPanel__NotificationSubscriptions"
        />
      }
    </React.Fragment>
  );
};

export default DetailsAndPreferences;